<template>
    <h1> 404 Page Not Found </h1>
</template>

<script>
export default {
    name: "Not Found"
}
</script>

<style scoped>

</style>