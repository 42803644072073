import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/404.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/providers/peter-lueninghoener",
    name: "Peter Lueninghoener",
    component: () => import("../views/providers/PeterLueninghoener.vue")
  },
  {
    path: "/providers/heather-pardun",
    name: "Heather Pardun",
    component: () => import("../views/providers/HeatherPardun.vue")
  },
  {
    path: "/providers/jenna-kaup",
    name: "Jenna Kaup",
    component: () => import("../views/providers/JennaKaup.vue")
  },
  {
    path: "/providers/kacie-kopf",
    name: "Kacie Kopf",
    component: () => import("../views/providers/KacieKopf.vue")
  },
  {
    path: "/telemedicine",
    name: "Telemedicine",
    component: () => import("../views/Telemedicine.vue")
  },
  {
    path: "/providers/guy-fenske",
    name: "Guy Fenske",
    component: () => import("../views/providers/GuyFenske.vue")
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("../views/Services.vue")
  },
  {
    path: "/patient-forms",
    name: "Patient Forms",
    component: () => import("../views/PatientForms.vue")
  },
  {
    path: "/office-staff",
    name: "Office Staff",
    component: () => import("../views/OfficeStaff.vue")
  },
  //footer menu
  {
    path: "/office-policies",
    name: "Office Policies",
    component: () => import("../views/OfficePolicies.vue")
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () => import("../views/PrivacyPolicy.vue")
  },
  {
    path: "/terms-of-use",
    name: "Terms of Use",
    component: () => import("../views/TermsOfUse.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  }
})

export default router
