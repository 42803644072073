import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import Primevue from "primevue/config";
import Button from "primevue/button";
import Menubar from "primevue/menubar";

import "primevue/resources/themes/saga-blue/theme.css"; // theme
import "primevue/resources/primevue.min.css";           // core css
import "primeicons/primeicons.css";                     // icons


const app = createApp(App).use(router);
app.use(Primevue);
//TODO: Google analytics -- https://matteo-gabriele.gitbook.io/vue-gtag/v/next/
app.component("Button", Button);
app.component("Menubar", Menubar);

app.mount("#app");

// createApp(App).mount('#app')
