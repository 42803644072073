<template>
    <footer>
        <Menubar :model="items">
            <div class="test"> test </div>
        </Menubar>
        Copyright &copy; 2013 - {{currentYear}} Elkhorn Valley Family Medicine
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
            items: [
                {
                    label: "Site Map",
                    to: "/site-map"
                },
                {
                    label: "Office Policies",
                    to: "/office-policies"
                },
                {
                    label: "Privay Policy",
                    to: "/privacy-policy"
                },
                {
                    label: "Terms of Use",
                    to: "/terms-of-use"
                }
            ]
        }
    }
}
</script>

<style scoped>
    footer {
        background-color: #7F2532;
        color: #ffffff;
        padding: 7.5px 0;
        padding-bottom: 25px;
    }
    a {
        color: #ffffff;
    }
    /* Deep selector -- selects only the footer menu bar in scoped css*/
    :deep(.p-menubar) {
        background-color: #7F2532;
        border: none;
    }
    :deep(.p-menubar-root-list) {
        margin: auto;
    }
    :deep(.p-menuitem) {
        padding: 0rem;
    }
    :deep(.p-menuitem-link) {
        padding: 0rem;
        color:#ffffff;
    }
    :deep(.p-menuitem-text) {
        color: #ffffff !important;
    }
    :deep(.p-menuitem-link::after) {
        padding-left: 2rem;
        /* content: ' | '; */
        color: #ffffff;
    }
    /* :deep(.p-submenu-list) {  */
    :deep(.p-menubar-root-list) {
        /* override menu background color since plain white clashes */
        background-color: #7f2532 !important;
    }
    /* :deep(.p-menubar-root-list li a):after {
        content: "|||";
        color:#ffffff;
    } */
</style>