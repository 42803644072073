<template>
  <Ticker />
  <Navbar />
  <router-view/>  <!-- display view contents here -->
  <Footer />
</template>


<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import Ticker from "@/components/Ticker.vue";

export default {
  components: {
    Footer,
    Navbar,
    Ticker
  }
}
</script>

<style>
#app {
  background-color: #fefbfb;
  color: #2c3e50;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  margin: -7.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

}
</style>
