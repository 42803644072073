<template>
   <div class="ticker-wrapper">
        <div class="ticker">
            <div class="ticker__item">Flu, Covid, and RSV immunizations
              are now available as well as the most recent Shingles vaccine (two shot
              series) are all available by appointment or walk-in. Masks are no longer
              required but everyone with an upper respiratory infection are asked to
              wear a mask to reduce the spread of that infection to other patients in
              our office.
            </div>
        </div>
   </div>
</template>
<script>
export default {
  name: 'Ticker'
}
</script>

<!-- Add CSS here -->
<style scoped>
.ticker {
    box-sizing: content-box;
    color:#ffffff;
    display: inline-block;
    height: 3rem;
    line-height: 3rem;
    padding-left: 100%;
    white-space: nowrap;

    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-name: ticker;
            animation-name: ticker;
    -webkit-animation-duration: 50s;
            animation-duration: 50s;
}
.ticker-wrapper {
    background-color:#c71104;
    height: 3rem;
    /* position: fixed; */
    overflow: hidden;
    top: 0;
    width: 100%
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
</style>