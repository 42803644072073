<template>

  <div class="home">
    <router-link to="/telemedicine">
      <img class="responsive" src="../assets/home/telemedicine.jpg" alt="telemedicine">
    </router-link>
  </div>


<Card class="card">
  <template #header>
      <img alt="healthcare services" src="@/assets/home/medical-equipment.gif">
  </template>
  <template #title>
      Healthcare Servies
  </template>
  <template #content>
    <p>
      Check out our <router-link to="/services">Clinical Healthcare Services</router-link> page to see all the services our clinic has to offer. 
    </p>
    <p> 
      <b>Clinic hours: </b> 8:15-5:00
    </p>
  </template>
</Card>
<Card class="card">
  <template #header>
      <img alt="healthcare services" src="@/assets/home/office-location.png">
  </template>
  <template #title>
      Location &amp; Directions
  </template>
  <template #content>
    <p>
      304 East Douglas Street <br />
      O'Neill, NE 68763
    </p>
    <p> 
      <a href="https://www.google.com/maps?daddr=304 East Douglas Street ONeill NE" target="_blank">Get Directions</a>
    </p>
  </template>
</Card>
<Card class="card">
  <template #header>
      <img alt="healthcare services" src="@/assets/home/chiropractor-logo.gif">
  </template>
  <template #title>
      Chiropractic Servies
  </template>
  <template #content>
    <p>
      We also offer chiropractic services. We recently welcomed Dr. Guy Fenske.
    </p>
    <p> 
      <b>Chiropractic hours: </b>7:00-4:00
    </p>
  </template>
</Card>
</template>

<script>
// @ is an alias to /src
import Card from "primevue/card";

export default {
  name: 'Home',
  components: {
    Card
  }
}
</script>

<style scoped>
  .card {
    width: 25rem;
    margin-bottom: 2em;
    display: inline-block;
    margin-right: 10px;
  }
  .card img {
    height: 150px;
    width: 200px;
  }
  .home {
    padding: 5% 0 5% 0;
    width: 60%;
    margin: 0 auto;
  }
  .responsive {
    width: 100%;
    height: auto;
  }
</style>
