<template>
   <div class="header">
      <img class="headerLogo" src="@/assets/home/EVFM-logo.png">
   </div>
   <div class="location">
      <b> Clinic hours: </b> 8:15 - 5:00 <br />
      <b> Chiropractic: </b> 7:00 - 4:00 <br />
   </div>
   <Menubar class="headerMenu" :model="items">
      <template #start>
         <img class="headerLogoNoText" src="@/assets/home/EVFM-logo-no-text.png">
      </template>
      <template #end>
         <i class="pi pi-phone"></i> <a class="tel" href="tel:+14023364222"> (402) 336-4222 </a> <br />
         <i class="pi pi-print"></i> (402) 336-4228
      </template>
   </Menubar>
    <!-- <Button label="greet"></Button> -->
</template>

<script>
export default {
  name: 'NavBar',
  data() {
        return {
            items: [
                {
                   label: 'Home',
                   to: '/'
                },
                {
                   label: 'Providers',
                   items:[
                      {
                         label: 'Peter Lueninghoener MD',
                         to: '/providers/peter-lueninghoener'
                      },
                      {
                         label: 'Heather Pardun APRN',
                         to: '/providers/heather-pardun'
                      },
                      {
                         label: 'Jenna Kaup APRN',
                         to: '/providers/jenna-kaup'
                      },
                      {
                         label: 'Kacie Kopf APRN',
                         to: '/providers/kacie-kopf'
                      }
                   ]
                },
                {
                   label: 'Telemedicine',
                   to: '/telemedicine'
                },
                {
                   label: 'Chiropractic',
                   items:[
                      {
                         label: 'Guy Fenske DC',
                         to: '/providers/guy-fenske'
                      }
                   ]
                },
                {
                   label: 'Clincial Services',
                   to: '/services'
                },
                {
                   label: 'Office Staff',
                   to: '/office-staff'
                },
                {
                   label: 'Patient Forms',
                   to: '/patient-forms'
                }
             ]
        }
    }
//   props: {
//     msg: String
//   }
}
</script>

<!-- Add CSS here -->
<style>
.headerLogo {
   width: 350px;
   display: block;
   float: left;
   padding: 5px;
}
.headerLogoNoText {
   display: none;
   width: 65px;
}
.header {
   width: 40%;
}
.location {
   float: right;
   padding: .5%;
   padding-top: 1.5%;
}
.p-menubar {
   clear: both;
}
/* Need separate class for header menu since there's also a menu in the footer */
.headerMenu { 
   /* override menu background color since plain white clashes */
   background-color: #fdf7f7 !important;
}
.p-submenu-list { 
   /* override menu background color since plain white clashes */
   background-color: #fdf7f7 !important;
}
.tel {
   color:#495057;
   text-decoration: none;
}

@media screen and (max-width: 960px) {
   .headerLogo {
      display: none;
   }
   .headerLogoNoText {
      display: block;
   }
   .location {
      display: none;
   }
}
</style>